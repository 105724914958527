import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import TableView from './tableview';
import AdminAPI from '../service/adminapi';
import { withStylesPropTypes, downloadExcelFile, getHideTrafficForReason } from '../helper/misc';
import XLSX from 'xlsx';

const useStyles = (() => ({
  toolbar: {
    height: '64px',
    flexGrow: 0,
  },
  dateBar: {
    marginRight: '5px',
  },
  backBtn: {
    margin: '5px 15px 0px 0px',
  },
  menuLink: {
    color: 'white',
    textDecoration: 'none',
    marginLeft: '20px'
  },
  userName: {
    fontSize: '1.2em',
    display: 'inline-block',
    fontWeight: 'bold',
    marginLeft: '15px',
    verticalAlign: 'bottom',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}));

const reportColumns = [
  { label: 'User', dataKey: 'user' },
  { label: 'Role', dataKey: 'role' },
  { label: 'Total', dataKey: 'total', numeric: true },
  { label: 'Parking Lots', dataKey: 'parkingLots', numeric: true },
  { label: 'Non-parking Lots', dataKey: 'nonParkingLots', numeric: true },
  { label: 'Pending', dataKey: 'pending', numeric: true },
  { label: 'Accepted', dataKey: 'accepted', numeric: true },
  { label: 'Failed', dataKey: 'failed', numeric: true },
];
const detailsColumns = [
  { label: 'List Name', dataKey: 'listName' },
  { label: 'Total', dataKey: 'total', numeric: true },
  { label: 'Parking Lots', dataKey: 'parkingLots', numeric: true },
  { label: 'Non-parking Lots', dataKey: 'nonParkingLots', numeric: true },
  { label: 'Pending', dataKey: 'pending', numeric: true },
  { label: 'Accepted', dataKey: 'accepted', numeric: true },
  { label: 'Failed', dataKey: 'failed', numeric: true },
];

class AdminUserReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: new Date(),
      dateTo: new Date(),
      report: [],
      currentUser: null,
    };
  }

  componentDidMount() {
    this.loadReport();
  }

  onRowSelected(row) {
    this.setState({
      currentUser: {
        id: row.id,
        name: row.user,
        role: row.role,
      },
    }, () => this.loadUserDetailsReport());
  }

  onBackToReport() {
    this.setState({
      currentUser: null,
    });
  }

  handleDateFromChange(date) {
    this.setState({
      dateFrom: date,
    }, () => this.loadReport());
  }

  handleDateToChange(date) {
    this.setState({
      dateTo: date,
    }, () => this.loadReport());
  }

  loadReport() {
    const me = this;
    const { dateFrom, dateTo, currentUser } = this.state;

    AdminAPI.getUsersReport(Moment(dateFrom).format('YYYY-MM-DD'), Moment(dateTo).format('YYYY-MM-DD')).then((report) => {
      me.setState({
        report,
      });
    });

    if (currentUser) this.loadUserDetailsReport();
  }

  aliExcelExport() {
    const { dateFrom, dateTo } = this.state;
    try {
      AdminAPI.getExcelReport(Moment(dateFrom).format('YYYY-MM-DD'), Moment(dateTo).format('YYYY-MM-DD')).then((report) => {
        AdminAPI.getUsers().then((data) => {
          const wb = XLSX.utils.book_new();
          let userMap = this.createUserMap(data);
          const ws = XLSX.utils.json_to_sheet(this.mapToAliField(report, userMap));
          XLSX.utils.book_append_sheet(wb, ws, "updated_geofence_report");
          const xlsx = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'base64' });
          downloadExcelFile(`updated_geofence_report.xlsx`, xlsx);
        });
      });
    } catch (err) {
      console.log(err);
    }
  }
  createUserMap(users) {
    let obj = {};
    users.map((elem) => {
      obj[elem['id']] = elem;
    })
    return obj;
  }


  mapToAliField(report, userMap) {
    let mappedArray = []
      let duplicateObj = {};
      report = report.map((elem) => {
      try {
        let obj = {};
        let ali = elem['ali'] ? elem['ali'] : undefined;
        ali = this.convertALIFromAPI(ali);

        if (!duplicateObj[ali.ali]) {
          duplicateObj[ali.ali] = 1;
          obj['category'] = ali['category'];
          obj['listID'] = ali['listID'];
          obj['listName'] = ali['listName'];
          obj['ALI'] = ali['ali'];
          obj['polygonStatus'] = ali['polygonStatus'];
          obj['storeName'] = ali['storeName'];
          obj['address'] = ali['address'];
          obj['city'] = ali['city'];
          obj['state'] = ali['state'];
          obj['zip'] = ali['zip'];
          obj['geoAccuracy'] = ali['geoAccuracy'];
          obj['SQFT'] = ali['squareFootage'];
          obj['Manual SQFT'] = ali['manualSqft'];
          obj['sc'] = ali['sc'];
          obj['type']=ali['type'];
          obj['users'] = ali['users'];
          obj['dateEffective'] = ali['dateEffective'];
          obj['Latitude'] = ali['latitude'];
          obj['Longitude'] = ali['longitude'];
          obj['Parent ALI'] = ali.parent;
          obj['Parent Store Name'] = ali['parent'] ? ali['parent'].storeName : "";
          obj['Parent List Name'] = ali['parent'] ? ali['parent'].listName : "";
          obj['HideTraffic'] = ali['hideTraffic'];
          obj['HideFromApp'] = ali['hideFromApp'];
          obj['lastUpdate'] = ali['lastUpdate'];
          obj['hideTrafficFor'] = getHideTrafficForReason(elem['hideTrafficForReason']);
          let userList = [];
          if(ali['users']){
          Object.keys(ali['users']).forEach((uid) => {
            const user = userMap[uid];
            userList.push(user);
          });
          obj['users'] = userList.map((u) => u.email).join(', ');
        }
          mappedArray.push(obj);
        } 
      } catch (err) {
        console.log("error", err,elem);
      }
      });
   
    return mappedArray;

  }

  convertALIFromAPI(data) {
    let ret = null;
    if (typeof (data) === 'object') {
      ret = { polygons: [] };
      Object.keys(data).forEach((key) => {
       if (key === 'property') {
          const propertyList = data[key];
          ret.property = propertyList.length ? propertyList[0] : {};
        } else if (key === 'location') {
          if (data[key]) [ret.longitude, ret.latitude] = data[key].coordinates;
        } else if (key === 'originalLocation') {
          [ret.original_longitude, ret.original_latitude] = data[key].coordinates;
        } else {
          const value = data[key];
          switch (key) {
            case 'listId': ret.listID = value; break;
            case 'zipCode': ret.zip = value; break;
            case 'phoneNumber': ret.phone = value; break;
            case 'squareFootage': ret.squareFootage = Math.round(value); break;
            case 'latitude': break;
            case 'longitude': break;
            default: ret[key] = value; break;
          }
        }
      });
      ret.sc = ret.parent ? 'Y' : 'N';
    }
    return ret;
  }

  loadUserDetailsReport() {
    const { dateFrom, dateTo, currentUser } = this.state;
    AdminAPI.getUserReport(Moment(dateFrom).format('YYYY-MM-DD'), Moment(dateTo).format('YYYY-MM-DD'), currentUser.id).then((report) => {
      this.setState({
        currentUser: {
          ...currentUser,
          report,
        },
      });
    });
  }

  render() {
    const { classes } = this.props;
    const {
      report, dateFrom, dateTo, currentUser,
    } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.toolbar}>
          {!!currentUser && (
            <Button color="primary" variant="contained" startIcon={<ArrowBackIcon />} onClick={() => this.onBackToReport()} className={classes.backBtn}>Back</Button>
          )}
          <KeyboardDatePicker
            value={dateFrom}
            label="Date From"
            onChange={(date) => this.handleDateFromChange(date)}
            maxDate={dateTo}
            format="MM/DD/YYYY"
            className={classes.dateBar}
          />
          <KeyboardDatePicker
            value={dateTo}
            label="Date To"
            onChange={(date) => this.handleDateToChange(date)}
            minDate={dateFrom}
            format="MM/DD/YYYY"
            className={classes.dateBar}
          />
          {!!currentUser && (
            <div className={classes.userName}>{currentUser.name}</div>
          )}

          {
            <Button color="primary" variant="contained" className={classes.menuLink} onClick={() => this.aliExcelExport()}>EXCEL EXPORT</Button>
          }
        </div>



        <div className={classes.content}>
          <Slide direction="left" in={!currentUser} mountOnEnter unmountOnExit>
            <TableView
              data={report}
              columns={
                reportColumns.map((c) => {
                  let column = null;
                  switch (c.dataKey) {
                    case 'user': column = { ...c, width: 200, flexGrow: 1 }; break;
                    case 'role': column = { ...c, width: 200, flexGrow: 1 }; break;
                    default: column = { ...c, width: 180 }; break;
                  }
                  return column;
                })
              }
              onRowClick={(e) => { this.onRowSelected(e.rowData); }}
            />
          </Slide>
          <Slide direction="right" in={!!currentUser} mountOnEnter unmountOnExit>
            <TableView
              data={currentUser && currentUser.report ? currentUser.report : []}
              columns={
                detailsColumns.map((c) => {
                  let column = null;
                  switch (c.dataKey) {
                    case 'listName': column = { ...c, width: 200, flexGrow: 1 }; break;
                    default: column = { ...c, width: 180 }; break;
                  }
                  return column;
                })
              }
            />
          </Slide>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

AdminUserReport.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(AdminUserReport);
