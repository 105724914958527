import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { polygon as createTurfPolygon } from '@turf/helpers';
import { area } from '@turf/turf';
import booleanWithin from '@turf/boolean-within';
import ColorProvider from '../helper/colorprovider';
import UndoButton from './undobutton';
import MapSearchBar from './mapsearchbar';
import { withStylesPropTypes, getLetterIndex } from '../helper/misc';
import { handlePolygonOwnerOnEdit } from '../helper/polygon-helper';
import User from '../service/user';
import { ALI_POLYGONS_ZINDEX } from '../constants';
import MapStyleSelect from './mapstyleselect';

window.google = window.google ? window.google : {};
const { google } = window;

const useStyles = (() => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  map: {
    height: '100%',
  },
  addressBar: {
    width: '50%',
  },
  addressBarInput: {
    backgroundColor: '#ffffff',
  },
}));

const getPolygonsWithzIndex = (polygons, aliPolygons = false) => {
  if (_.isEmpty(polygons)) return [];
  const polygonsWithArea = polygons.map((p) => ({ ...p, area: Number(area(createTurfPolygon([p.coordinates]))) || 0 }));
  const polygonsOrderedBySize = _.sortBy(polygonsWithArea, (p) => p.area).reverse();
  const polygonsWithZindex = (polygonsOrderedBySize || []).map((p, i) => ({ ...p, zIndex: (aliPolygons ? ALI_POLYGONS_ZINDEX : 0) + i }));
  return polygonsWithZindex;
};

class Map extends React.Component {
  static latLngsToPixel(map, polygon) {
    if (!map || !polygon) {
      return {
        posLeft: 0,
        posTop: 0,
      };
    }
    const vertex = polygon.getPath().getAt(0);
    const lat = vertex.lat();
    const lng = vertex.lng();
    // Projection variables.
    const projection = map.getProjection();
    const topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast());
    const bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest());
    const scale = 2 ** map.getZoom();

    // Create our point.
    const point = projection.fromLatLngToPoint(
      new google.maps.LatLng(lat, lng),
    );

    // Get the x/y based on the scale.
    const posLeft = Math.floor((point.x - bottomLeft.x) * scale);
    const posTop = Math.floor((point.y - topRight.y) * scale);
    return {
      posLeft,
      posTop,
    };
  }

  static removePolygonVertex(polygon) {
    if (polygon.vertex) {
      polygon.vertex.forEach((v) => {
        v.setMap(null);
      });
      // eslint-disable-next-line no-param-reassign
      delete polygon.vertex;
    }
  }

  constructor(props) {
    super(props);

    this.map = null;
    this.markers = [];
    this.currentMarker = null;
    this.polygons = [];
    this.aliAreaPolygons = [];
    this.currentPolyline = null;
    this.polylines = [];
    this.currentPolygon = null;
    this.colorProvider = new ColorProvider();

    this.state = {
      map: null,
      lastAli: null,
      is2d: true,
      undoButton: {},
      showUndoButton: true,
    };
  }

  componentDidMount() {
    window.initMap = this.initMap.bind(this);
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9yv5NdM1u_Y2viDwLy5XxC1k8UKbOydc&libraries=drawing,places&callback=initMap&region=us&language=en';
    window.document.body.appendChild(script);
  }

  componentDidUpdate(prevProps) {
    const {
      ali,
      aliAreaList,
      selectedPolygon,
    } = this.props;
    if (prevProps.ali !== ali) {
      this.onALIRecevied(ali);
    }
    if (prevProps.aliAreaList !== aliAreaList) {
      this.onALIAreaListReceived(aliAreaList);
    }
    if (prevProps.selectedPolygon !== selectedPolygon) {
      this.onSelectedPolygonChange(selectedPolygon);
    }
  }

  onALIRecevied(ali) {
    const { lastAli } = this.state;
    this.colorProvider.reset();
    this.removeMarkers();
    this.removePolygons();
    this.setUndoButtonVisibleStatus(false);
    if (ali) {
      const aliPolygons = getPolygonsWithzIndex(ali.polygonsToDisplay, true);
      this.drawPolygons(aliPolygons);
      this.drawMarkers(ali);

      // check if polygons can be drawn or not
      // polygons are not supposed to be shown in the map until overlap polygons button is clicked
      if (ali.canDrawOverlaps && !_.isEmpty(ali.nearByAliPolygons)) {
        const nearByAliPolygons = getPolygonsWithzIndex(ali.nearByAliPolygons);
        this.drawPolygons(nearByAliPolygons);
      }

      if (ali.ali) {
        if (lastAli !== ali.ali) {
          this.setCenter(Map.getALICenter(ali));
          this.setZoom(Map.getALIZoom(ali));
          this.setState({ lastAli: ali.ali });
        }
        this.putMarker();
      }
    }
    this.validatePolygonsDrawing(true);
  }

  onALIAreaListReceived(aliAreaList) {
    this.removeALIAreaPolygons();
    if (aliAreaList) {
      this.drawALIAreaPolygons(aliAreaList.filter((a) => a.polygons && a.polygons.length));
    }
  }

  onSelectedPolygonChange(selectedPolygon) {
    const { ali } = this.props;
    if (ali && ali.polygonsToDisplay) {
      ali.polygonsToDisplay.forEach((p) => {
        // eslint-disable-next-line no-param-reassign
        delete p.selectedVertexIndex;
      });
      if (selectedPolygon) {
        const polygon = this.polygons.find((p) => p.polygonIndex === selectedPolygon.polygonIndex);
        if (polygon) this.selectPolygon(polygon, selectedPolygon.vertexIndex);
      }
    }
  }

  onALIChanged(ali) {
    const { onALIChanged } = this.props;
    onALIChanged(ali);
  }

  onNewMarker(marker) {
    const { ali } = this.props;
    if (!ali.markers) ali.markers = [];
    this.clearUndoBtnOptions();
    ali.markers.push(marker.getPosition());
    marker.setMap(null);
    this.onALIChanged({ ...ali });
  }

  onNewPolygon(polygon) {
    this.clearUndoBtnOptions();
    this.polygons.push(polygon);
    this.rememberPolygon(polygon, true);
  }

  onPolygonEdited(polygon) {
    const me = this;
    if (this.onPolygonEditedTimer) clearTimeout(this.onPolygonEditedTimer);
    this.onPolygonEditedTimer = setTimeout(() => {
      me.onPolygonEditedTimer = null;
      me.rememberPolygon(polygon);
    }, 100);
  }

  onMarkerMoved() {
    if (this.currentMarker) {
      const { ali } = this.props;
      if (!this.aliHasOriginalPosition()) {
        ali.original_latitude = ali.latitude;
        ali.original_longitude = ali.longitude;
      }
      const position = this.currentMarker.getPosition();
      ali.latitude = position.lat();
      ali.longitude = position.lng();
      ali.geoAccuracy = 'AGGDATA_VERIFIED: ROOFTOP';
      ali.modified = true;
      ali.position_modified = true;
      this.onALIChanged({ ...ali });
    }
  }

  onMapIdle() {
    const { onMapIdle } = this.props;
    const { map } = this.state;
    if (map) {
      this.validatePolygonsDrawing();
      const bounds = map.getBounds();
      if (bounds) {
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        onMapIdle({ sw: [sw.lng(), sw.lat()], ne: [ne.lng(), ne.lat()] });
      }
    }
  }

  static getALICenter(ali) {
    return { lat: ali.latitude, lng: ali.longitude };
  }

  static getALIZoom(ali) {
    return (ali ? 18 : 10);
  }

  onSearchLatLng(latLng) {
    const { map } = this.state;
    if (map) {
      this.setCenter(latLng);
      const position = new google.maps.LatLng(latLng.lat, latLng.lng);
      const marker = new google.maps.Marker({
        map,
        position,
        draggable: true,
        icon: Map.getMarkerSymbol('#ee0000'),
        zIndex: 100,
      });
      this.onNewMarker(marker);
    }
  }

  setCenter(center) {
    const { map } = this.state;
    if (map) {
      map.setCenter(new google.maps.LatLng(center.lat, center.lng));
    }
  }

  setZoom(zoom) {
    const { map } = this.state;
    if (map) {
      map.setZoom(zoom);
    }
  }

  static getMarkerSymbol(color) {
    return {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000000',
      strokeWeight: 2,
      scale: 1,
    };
  }

  setUndoBtnOptions() {
    const { map, undoButton } = this.state;
    const { posLeft, posTop } = Map.latLngsToPixel(map, this.currentPolygon);
    const undoBtnWithPositions = { ...undoButton, posLeft, posTop };
    this.setState({
      undoButton: undoBtnWithPositions,
    });
  }

  setALIPolygonCoords() {
    const { ali } = this.props;
    const coords = this.currentPolygon.getPath().getArray().map((p) => [p.lng(), p.lat()]);
    coords.push(coords[0]);
    const index = ali.polygons.findIndex((p, idx) => idx === this.currentPolygon.polygonIndex);
    if (index >= 0) {
      ali.polygons[index].coordinates = coords;
      this.onALIChanged(ali);
    }
  }

  setUndoButtonVisibleStatus(status) {
    this.setState({ showUndoButton: status });
  }

  clearUndoBtnOptions() {
    this.setState({
      undoButton: {},
    });
  }

  canEdit() {
    const { editable, ali } = this.props;
    return editable && !(ali && ali.readOnly);
  }

  validatePolygonsDrawing(forceValidate) {
    const { map, is2d } = this.state;

    const currentIs2d = (map.getTilt() === 0);
    if (currentIs2d !== is2d || forceValidate) {
      this.setState({
        is2d: currentIs2d,
      });
      const modes = [google.maps.drawing.OverlayType.MARKER];
      const canEdit = currentIs2d && this.canEdit();
      if (canEdit) modes.push(google.maps.drawing.OverlayType.POLYGON);
      this.drawingManager.setOptions({
        drawingControlOptions: {
          ...this.drawingManager.drawingControlOptions,
          drawingModes: modes,
        },
      });

      this.polygons.forEach((p) => {
        p.setOptions(canEdit ? {
          clickable: true,
        } : {
          clickable: false,
          draggable: false,
          editable: false,
        });
      });
    }

    return currentIs2d;
  }

  putMarker() {
    const me = this;
    const { ali } = this.props;
    const { map } = this.state;
    if (map) {
      const position = new google.maps.LatLng(ali.latitude, ali.longitude);
      let marker = new google.maps.Marker({
        map,
        position,
        draggable: this.canEdit(),
        icon: Map.getMarkerSymbol('#ee0000'),
        zIndex: 100,
      });
      this.markers.push(marker);
      this.currentMarker = marker;

      google.maps.event.addListener(marker, 'dragend', () => {
        me.onMarkerMoved();
      });

      if (this.aliHasOriginalPosition()) {
        const originalPosition = new google.maps.LatLng(ali.original_latitude, ali.original_longitude);
        marker = new google.maps.Marker({
          map,
          position: originalPosition,
          icon: Map.getMarkerSymbol('#aaaaaa'),
          zIndex: 50,
        });
        this.markers.push(marker);

        const polyline = new google.maps.Polyline({
          path: [originalPosition, position],
          strokeOpacity: 0,
          icons: [{
            icon: {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              strokeColor: '#00bff3',
              scale: 4,
            },
            offset: '0',
            repeat: '20px',
          }, {
            icon: {
              path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
              strokeOpacity: 1,
              strokeColor: '#00bff3',
              scale: 2,
            },
          }],
          map,
        });
        this.polylines.push(polyline);
        this.currentPolyline = polyline;
      }
    }
  }

  removeMarkers() {
    this.markers.forEach((m) => {
      m.setMap(null);
    });
    this.polylines.forEach((p) => {
      p.setMap(null);
    });
    this.markers = [];
    this.polylines = [];
    this.currentMarker = null;
    this.currentPolyline = null;
  }

  removePolygons() {
    this.polygons.forEach((p) => {
      Map.removePolygonVertex(p);
      p.setMap(null);
    });
    this.polygons = [];
    this.currentPolygon = null;
  }

  removeALIAreaPolygons() {
    this.aliAreaPolygons.forEach((p) => {
      p.setMap(null);
    });
    this.aliAreaPolygons = [];
  }

  aliHasOriginalPosition() {
    const { ali } = this.props;
    return Number.isFinite(ali.original_latitude) && Number.isFinite(ali.original_longitude);
  }

  drawMarkers(ali) {
    const me = this;
    const { map } = this.state;
    const { markers } = ali;
    if (markers && map) {
      markers.forEach((position, i) => {
        const marker = new google.maps.Marker({
          position,
          map,
          draggable: true,
        });
        me.markers.push(marker);
        google.maps.event.addListener(marker, 'dragend', () => {
          markers[i] = marker.getPosition();
        });
        google.maps.event.addListener(marker, 'dblclick', () => {
          markers.splice(i, 1);
          me.onALIChanged({ ...ali });
        });
      });
    }
  }

  drawPolygons(polygons) {
    const me = this;
    const { map } = this.state;
    if (polygons && map) {
      polygons.forEach((p) => {
        if (!p.hidden) {
          const poly = new google.maps.Polygon({
            paths: [p.coordinates.slice(0, p.coordinates.length - 1).map((c) => new google.maps.LatLng(c[1], c[0]))],
            map,
            strokeColor: me.colorProvider.get(p.colorIndex),
            fillColor: 'transparent',
            zIndex: p.zIndex || 1,
          });
          poly.polygonIndex = p.index;
          if (!p.nonSelectable) {
            me.makeSelectable(poly);
          }
          me.polygons.push(poly);
        }
      });
    }
  }

  drawALIAreaPolygons(alis) {
    const me = this;
    const { map } = this.state;

    const { ali } = this.props;
    let propertyPolygons;
    if (ali && ali.polygons) {
      try {
        propertyPolygons = ali.polygons.map((p) => createTurfPolygon([p.coordinates]));
      // eslint-disable-next-line no-empty
      } catch (err) { }
    }

    if (alis && map) {
      alis.forEach((a) => {
        a.polygons.forEach((p) => {
          let within = false;
          if (propertyPolygons) {
            try {
              const cp = createTurfPolygon([p.coordinates]);
              within = propertyPolygons.some((pp) => booleanWithin(cp, pp));
            // eslint-disable-next-line no-empty
            } catch (err) { }
          }

          const strokeColor = (p.properties || {}).nap ? '#00ffff' : '#99ff99';
          const fillColor = within ? strokeColor : '#ff0000';
          const poly = new google.maps.Polygon({
            paths: [p.coordinates.slice(0, p.coordinates.length - 1).map((c) => new google.maps.LatLng(c[1], c[0]))],
            map,
            strokeColor,
            fillColor,
            zIndex: 0,
            clickable: false,
          });
          poly.ali = a.ali;
          me.aliAreaPolygons.push(poly);
        });
      });
    }
  }

  rememberPolygon(polygon, invalidate) {
    let poly = null;

    if (polygon) {
      const { ali } = this.props;

      const coords = [];
      const path = polygon.getPath();
      for (let i = 0; i < path.getLength(); i += 1) {
        const c = path.getAt(i);
        coords.push([c.lng(), c.lat()]);
      }
      coords.push(coords[0]);

      if (!ali.polygons) ali.polygons = [];
      if (Number.isInteger(polygon.polygonIndex) && polygon.polygonIndex < ali.polygons.length) {
        poly = ali.polygons[polygon.polygonIndex];
        poly.coordinates = coords;
        poly.properties.status = User.getNextGeofenceStatus(poly);
      } else {
        polygon.polygonIndex = ali.polygons.length; // eslint-disable-line
        const polygonsCount = ali.polygons.filter((p) => !p.deleted).length;
        poly = {
          name: `${ali.ali ? ali.ali : 'Polygon'}-${getLetterIndex(polygonsCount)}`,
          coordinates: coords,
          properties: {
            status: User.getNewGeofenceStatus(),
            userRole: User.getRole(),
          },
        };
        ali.polygons.push(poly);
      }

      handlePolygonOwnerOnEdit(poly);
      ali.modified = true;

      if (invalidate) this.onALIChanged({ ...ali });
      else this.onALIChanged(ali);
    }
    return poly;
  }

  makeSelectable(polygon) {
    const me = this;
    const { onPolygonSelected } = this.props;
    polygon.setOptions({ clickable: true });
    google.maps.event.addListener(polygon, 'click', (e) => {
      const { vertex } = e;
      me.selectPolygon(polygon, vertex);
      onPolygonSelected({ polygonIndex: polygon.polygonIndex, vertexIndex: vertex });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  clearPathEventListeners(path) {
    google.maps.event.clearListeners(path, 'insert_at');
    google.maps.event.clearListeners(path, 'remove_at');
    google.maps.event.clearListeners(path, 'set_at');
  }

  initPathEventListeners(path) {
    const me = this;
    google.maps.event.addListener(path, 'insert_at', (idx) => {
      if (me.handleVertexRemove(me.currentPolygon, idx)) me.currentPolygon.selectedVertexIndex = undefined;
      else if (me.currentPolygon.selectedVertexIndex >= idx) me.currentPolygon.selectedVertexIndex += 1;
      me.onPolygonEdited(me.currentPolygon);
      me.invalidatePolygonVertex(me.currentPolygon);
    });
    google.maps.event.addListener(path, 'remove_at', (idx) => {
      if (me.currentPolygon.selectedVertexIndex > idx) me.currentPolygon.selectedVertexIndex -= 1;
      me.onPolygonEdited(me.currentPolygon);
      me.invalidatePolygonVertex(me.currentPolygon);
    });
    google.maps.event.addListener(path, 'set_at', (idx) => {
      me.handleVertexRemove(me.currentPolygon, idx);
      me.onPolygonEdited(me.currentPolygon);
      me.invalidatePolygonVertex(me.currentPolygon);
    });
  }

  selectPolygon(polygon, selectedVertex) {
    const me = this;

    if (this.currentPolygon) {
      Map.removePolygonVertex(this.currentPolygon);
      this.currentPolygon.setOptions({ draggable: false, editable: false });
      google.maps.event.clearListeners(this.currentPolygon, 'dragend');
      polygon.getPaths().forEach((path) => {
        me.clearPathEventListeners(path);
      });
    }

    this.currentPolygon = polygon;
    polygon.setOptions({ draggable: true, editable: true });

    if (selectedVertex >= 0) {
      me.currentPolygon.selectedVertexIndex = selectedVertex;
      me.invalidatePolygonVertex(me.currentPolygon);
    }

    google.maps.event.addListener(polygon, 'dragstart', () => {
      const coordinates = _.cloneDeep(me.currentPolygon.getPath());
      const undoButton = {
        coordinates,
      };
      this.setState({
        undoButton,
      });
    });

    google.maps.event.addListener(polygon, 'dragend', () => {
      this.setUndoButtonVisibleStatus(true);
      this.setUndoBtnOptions();
      this.setALIPolygonCoords();
    });

    polygon.getPaths().forEach((path) => {
      me.initPathEventListeners(path);
    });
  }

  invalidatePolygonVertex(polygon) {
    Map.removePolygonVertex(polygon);
    const path = polygon.getPath();
    if (polygon.selectedVertexIndex >= 0 && path.length > polygon.selectedVertexIndex) {
      const v = path.getAt(polygon.selectedVertexIndex);
      const { map } = this.state;
      const marker = new google.maps.Marker({
        position: v,
        zIndex: 0,
        map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8.5,
          strokeColor: '#00FF00',
          fillColor: '#00FF00',
          fillOpacity: 0.4,
          strokeWeight: 0.4,
        },
      });
      // eslint-disable-next-line no-param-reassign
      polygon.vertex = [marker];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleVertexRemove(polygon, idx) {
    let ret = false;

    const path = polygon.getPath();
    const numVertex = path.getLength();
    if (numVertex > 3) {
      const currentV = path.getAt(idx);
      let dupIdx;
      for (let i = 0; i < numVertex; i += 1) {
        if (i !== idx) {
          const v = path.getAt(i);
          const distance = google.maps.geometry.spherical.computeDistanceBetween(currentV, v);
          if (distance < 1) { // less that 1 meter
            dupIdx = i;
            break;
          }
        }
      }
      if (dupIdx >= 0) {
        ret = true;
        const minIdx = idx < dupIdx ? idx : dupIdx;
        const maxIdx = idx > dupIdx ? idx : dupIdx;
        if (maxIdx - minIdx <= minIdx + (numVertex - maxIdx)) {
          for (let i = maxIdx; i > minIdx; i -= 1) {
            path.removeAt(i);
          }
        } else {
          for (let i = numVertex - 1; i >= maxIdx; i -= 1) {
            path.removeAt(i);
          }
          for (let i = minIdx - 1; i >= 0; i -= 1) {
            path.removeAt(i);
          }
        }
      }
    }
    return ret;
  }

  initMap() {
    const me = this;

    const { center, zoom } = this.props;
    const { onMapReady } = this.props;
    const styles = [{
      featureType: 'poi.business',
      stylers: [{ visibility: 'on' }],
    }];

    const map = new google.maps.Map(document.getElementById('map'), {
      zoom,
      center: new google.maps.LatLng(center.lat, center.lng),
      mapTypeId: google.maps.MapTypeId.HYBRID,
      imageDateControl: true,
      gestureHandling: 'greedy',
      styles,
      rotateControl: true,
      mapTypeControl: false,
    });
    map.setTilt(0);

    const drawingModes = [google.maps.drawing.OverlayType.MARKER];
    if (this.canEdit()) drawingModes.push(google.maps.drawing.OverlayType.POLYGON);

    this.drawingManager = new google.maps.drawing.DrawingManager({
      map,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes,
      },
      circleOptions: {
        fillColor: '#ffff00',
        fillOpacity: 1,
        strokeWeight: 5,
        clickable: false,
        zIndex: 1,
        editable: true,
      },
      snappingCallback: ({ latLng, overlay }) => {
        let ret;

        if (typeof overlay.getPath === 'function') {
          const overlays = [...this.polygons];
          if (overlay) overlays.push(overlay);
          for (let oidx = 0; oidx < overlays.length; oidx += 1) {
            const path = overlays[oidx].getPath();
            if (path.getLength()) {
              const p1 = map.getProjection().fromLatLngToPoint(latLng);
              const pixelSize = 2 ** -map.getZoom();
              for (let i = 0; i < path.getLength(); i += 1) {
                const pt = path.getAt(i);
                const p2 = map.getProjection().fromLatLngToPoint(pt);
                const d = Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pixelSize;
                if (d < 10) {
                  ret = pt;
                  break;
                }
              }
            }
            if (ret) break;
          }
        }

        return ret || latLng;
      },
    });

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      me.drawingManager.setOptions({
        drawingMode: null,
      });
      switch (event.type) {
        case google.maps.drawing.OverlayType.POLYGON:
          me.onNewPolygon(event.overlay);
          break;
        case google.maps.drawing.OverlayType.MARKER:
          me.onNewMarker(event.overlay);
          break;
        default:
          break;
      }
    });

    google.maps.event.addListener(map, 'idle', () => {
      me.onMapIdle();
    });

    map.addListener('zoom_changed', () => {
      this.setUndoBtnOptions();
    });

    map.addListener('click', () => this.setUndoButtonVisibleStatus(false));

    this.setState({
      map,
    });

    onMapReady();
  }

  handleUndoPolygonMove() {
    const { map, undoButton } = this.state;
    // apply the old co-ordinates to restore the polygon to its original position
    const latLngs = undoButton.coordinates.getArray();
    this.currentPolygon.setPath(latLngs);
    this.currentPolygon.setMap(map);
    this.clearUndoBtnOptions();
    // get the old co-ordinates and changes the co-ordinates property
    // so that it gets reflected when the next time polygons are drawn
    this.setALIPolygonCoords();
    this.currentPolygon.getPaths().forEach((path) => {
      this.initPathEventListeners(path);
    });
  }

  render() {
    const { classes, onALISearch } = this.props;
    const { map, undoButton, showUndoButton } = this.state;
    const { posLeft, posTop, coordinates } = undoButton || {};
    return (
      <div className={classes.container}>
        {
          !_.isEmpty(coordinates)
          && showUndoButton
          && Number.isInteger(posLeft)
          && Number.isInteger(posTop) && (
            <UndoButton posLeft={posLeft} posTop={posTop} btnText="Undo Move" onUndoPolygonMove={() => this.handleUndoPolygonMove()} />
          )
        }
        <div id="map" className={classes.map} />
        <MapStyleSelect map={map} />
        <MapSearchBar map={map} onALISearch={onALISearch} onSearchLatLng={(latLng) => this.onSearchLatLng(latLng)} />
      </div>
    );
  }
}

Map.defaultProps = {
  ali: null,
  // nearByAliPolygons: [],
  center: { lat: 37.4419, lng: -122.1419 },
  zoom: 16,
  editable: true,
  aliAreaList: [],
  selectedPolygon: undefined,
  onMapReady: () => {},
  onMapIdle: () => {},
  onPolygonSelected: () => {},
  onALISearch: () => {},
};

Map.propTypes = {
  ali: PropTypes.shape(),
  editable: PropTypes.bool,
  aliAreaList: PropTypes.arrayOf(PropTypes.shape),
  onALIChanged: PropTypes.func.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  zoom: PropTypes.number,
  selectedPolygon: PropTypes.shape(),
  onMapReady: PropTypes.func,
  onMapIdle: PropTypes.func,
  onPolygonSelected: PropTypes.func,
  onALISearch: PropTypes.func,
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(Map);
