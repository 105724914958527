import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';
import { withStylesPropTypes } from '../helper/misc';
import initMapTiles, { MapTiles } from '../helper/map-tiles';

window.google = window.google ? window.google : {};
const { google } = window;

const useStyles = ((theme) => ({
  root: {
    width: '100%',
  },
  dropdown: {
    backgroundColor: '#ffffff',
    margin: theme.spacing(1),
  }
}));

function MapStyleSelect({ map, classes }) {
  const ref = useRef();
  const [selectedStyle, setSelectedStyle] = useState(undefined);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (map && ref.current) {
      initMapTiles(map);
      setOptions([
        { title: 'Google Satellite', styleId: google.maps.MapTypeId.HYBRID },
        { title: 'Google Map', styleId: google.maps.MapTypeId.ROADMAP },
        { styleId: MapTiles.OSM },
        { styleId: MapTiles.MAPBOX_STREETS },
        { styleId: MapTiles.MAPBOX_OUTOORDS },
        { styleId: MapTiles.MAPBOX_SATELLITE },
        { styleId: MapTiles.BING_ROAD },
        { styleId: MapTiles.BING_STATELLITE },
      ]);
      setSelectedStyle(map.getMapTypeId());
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(ref.current);
    }
  }, [map, ref]);

  const handleStyleSelect = (e) => {
    const { value } = e.target;
    setSelectedStyle(value);
    map.setOptions({
      mapTypeId: value
    });
  };

  return (
        <div className={classes.root} ref={ref}>
            <Select
                native
                value={selectedStyle}
                onChange={handleStyleSelect}
                className={classes.dropdown}
            >
                {
                    options.map((opt) => (
                        <option key={opt.styleId} value={opt.styleId}>{opt.title || opt.styleId}</option>
                    ))
                }
            </Select>
        </div>
  );
}

MapStyleSelect.defaultProps = {
  map: null,
};

MapStyleSelect.propTypes = {
  map: PropTypes.shape(),
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(MapStyleSelect);
