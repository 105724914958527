/* eslint-disable no-undef */

const adjustMapCoords = (coord, zoom) => {
  // eslint-disable-next-line no-bitwise
  const tilesPerGlobe = 1 << zoom;
  let x = coord.x % tilesPerGlobe;
  if (x < 0) {
    x = tilesPerGlobe + x;
  }
  return { x, y: coord.y };
};

const initMapBox = (map, tileName, styleId) => {
  const accessToken = 'sk.eyJ1IjoicmV0YWlsc3RhdCIsImEiOiJjbHgwZHh1MzkwZ21zMmtxMzF5bGdxZ2JxIn0.f3TafFKbHZJG6QCFkO3VFw';
  map.mapTypes.set(tileName, new google.maps.ImageMapType({
    getTileUrl(coord, zoom) {
      const { x, y } = adjustMapCoords(coord, zoom);
      return `https://api.mapbox.com/styles/v1/mapbox/${styleId}/tiles/512/${zoom - 1}/${x}/${y}?access_token=${accessToken}`;
    },
    tileSize: new google.maps.Size(512, 512),
    name: tileName,
    maxZoom: 20
  }));
};

const initBing = (map, tileName, tilesetId) => {
  const sharedKey = 'FrEZFUg8l7VMJnBjmbJ23F01zaIX54h8Oz0JteuxlonVrAdbbg0VJQQJ99AFACYeBjFcrqjBAAAgAZMPLcuf';
  map.mapTypes.set(tileName, new google.maps.ImageMapType({
    getTileUrl(coord, zoom) {
      const { x, y } = adjustMapCoords(coord, zoom);
      return `https://atlas.microsoft.com/map/tile?api-version=2024-04-01&tileSize=512&tilesetId=${tilesetId}&zoom=${zoom - 1}&x=${x}&y=${y}&subscription-key=${sharedKey}`;
    },
    tileSize: new google.maps.Size(512, 512),
    name: tileName,
    maxZoom: 20
  }));
};

const initOSM = (map, tileName) => {
  map.mapTypes.set(tileName, new google.maps.ImageMapType({
    getTileUrl(coord, zoom) {
      const { x, y } = adjustMapCoords(coord, zoom);
      return `https://tile.openstreetmap.org/${zoom}/${x}/${y}.png`;
    },
    tileSize: new google.maps.Size(256, 256),
    name: tileName,
    maxZoom: 18
  }));
};

export const MapTiles = {
  MAPBOX_SATELLITE: 'Mapbox Satellite',
  MAPBOX_OUTOORDS: 'Mapbox Outdoors',
  MAPBOX_STREETS: 'Mapbox Streets',
  OSM: 'Open Street Map',
  BING_ROAD: 'Bing Road',
  BING_STATELLITE: 'Bing Satellite',
};

export default function initMapTiles(map) {
  initMapBox(map, MapTiles.MAPBOX_SATELLITE, 'satellite-v9');
  initMapBox(map, MapTiles.MAPBOX_OUTOORDS, 'outdoors-v12');
  initMapBox(map, MapTiles.MAPBOX_STREETS, 'streets-v12');
  initOSM(map, MapTiles.OSM);
  initBing(map, MapTiles.BING_ROAD, 'microsoft.base.road');
  initBing(map, MapTiles.BING_STATELLITE, 'microsoft.imagery');
}
